import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import {
  Homecontent,
  CardsDetail,
  WelcomeContainer,
  ParentContainer,
} from "./Welcome.style";
import Button from "../Button";
import Homecards from "../Homecards/Homecards";
import {
  batLogo,
  eocLogo,
  rpmLogo,
  marketLogo,
} from "../../assets/images/Welcome/index";
import { useNavigate } from "react-router-dom";

export const Welcome = () => {
  const navigate = useNavigate();
  return (
    <ParentContainer>
      <div>
        <Navbar />
        <Homecontent>
          <div>
            <WelcomeContainer>
              <h1>Welcome to Vertical-IQ</h1>
              <div>
                <p>
                  At Vertical-IQ we provide Risk Management tools for the
                  residential new construction industry.
                </p>
                <p>
                Drawing from our deep expertise in Construction Finance, 
                we specialize in evaluating the crucial aspects of residential 
                construction projects. From a thorough budget analysis to 
                real estate market insights, we assess potential risks and 
                ensure you’re informed early on about any shifts in project timelines. 
                Partner with us to confidently navigate your construction journey from 
                start to finish.
                </p>
                <p>
                Discover Vertical-IQ's cutting-edge Budget Analysis tool, designed to 
                streamline your analysis of construction budgets. This powerful tool evaluates 
                your budget against similar projects, pinpointing missing items and potential 
                shortfalls across 12 key cost categories—from Foundation and Framing to MEP 
                (Mechanical, Electrical, Plumbing) and Interior Finishes like cabinets, countertops, 
                and appliances. Get clear insights into the adequacy of your construction budget, 
                compared to peer projects, with a detailed report that highlights potential shortages. 
                The tool also provides a comprehensive line-item breakdown, allowing you to review 
                other costs such as utilities, driveways, and landscaping based on your project’s unique needs. 
                Take control of your project’s financial health with confidence.
                </p>
                <p>
                Introducing our innovative, patent-pending EOC (End of Construction) model designed to 
                estimate the completion dates of residential single family and townhome construction projects. 
                Our model helps you detect schedule changes, offering valuable insights into whether a 
                project is likely to finish by its expected completion date. Perfect for those managing multiple projects, 
                this tool identifies which projects need your attention and which are on track, so you can prioritize 
                effectively. Plus, for our partners who provide monthly project updates, we offer in-depth analysis of 
                interest carry and the impact on Interest Reserves due to potential project slowdowns. 
                Stay proactive, informed, and in control with our powerful predictive model.
                </p>
                <p>
                  Contact us today to learn more about how we can help you as a
                  builder, lender, inspection company or due diligence firm to
                  get a firm grasp of project risks.
                </p>
              </div>
              <Button
                onClick={() => {
                  navigate("/contact-us");
                }}
              >
                Get Started
              </Button>
            </WelcomeContainer>
            <CardsDetail>
              <Homecards
                image={batLogo}
                title="BAT - Budget Assessment Tool"
                description="Automated analysis of the budget for a residential construction project, assessing 12 key components of the budget."
              />
              <Homecards
                image={eocLogo}
                title="EOC - End of Construction"
                description="Vertical-IQ’s patent-pending model for predicting when a residential (Single Family or Townhome) construction project will be completed, providing early insights into scheduling issues."
              />
              {/* <Homecards
                image={rpmLogo}
                title="RPM - Risk Performance Monitor"
                description="Providing a top down view of the project and KPIs to monitor."
              />
              <Homecards
                image={marketLogo}
                title="Market IQ"
                description="Providing real estate market context to your proposed project. Understand the state of your local market before making that decision to build."
              /> */}
            </CardsDetail>
          </div>
        </Homecontent>
        <Footer></Footer>
      </div>
    </ParentContainer>
  );
};

export default Welcome;
