import styled from 'styled-components';

export const ReportContainer = styled.div`
  width: 1056px;
  background: white;
`;

export const Page = styled.div`
  min-height: 816px;
  padding: 24px;
  break-after: page;
`;

export const CoverPage = styled(Page)`
  border: "1px solid black";
  min-height: 816px;
  min-width: 1056px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 32px;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

export const ChartPage = styled(Page)`
  h2 {
    width: 100%;
  }
`;
