export const SET_PROJECTS = "SET_PROJECTS";
export const SET_UPDATE_PROJECT = "SET_UPDATE_PROJECT";
export const SET_DELETE_PROJECT = "SET_DELETE_PROJECT";

export const SET_PROJECTS_BY_ID = "SET_PROJECTS_BY_ID";
export const SET_CREATE_PROJECT = "SET_CREATE_PROJECT";

export const SET_USER_INFO = "SET_USER_INFO";
export const roles = {
  admin: ["create_user"],
  account_manager: ["all"],
  account_executive: ["read"],
};

export const SET_USER_EMAIL = "SET_USER_EMAIL";
export const SET_APOLLO_CLIENT = "SET_APOLLO_CLIENT";

export const PROJECT_TYPE_OPTIONS = [
  {
    value: 1,
    label: "Single Family",
  },
  {
    value: 2,
    label: "Townhome",
  },
  {
    value: 3,
    label: "MultiFamily",
  },
];

export const PROJECT_DEFAULT_INPUT_ERROR = {
  "Project Name": { status: true, message: "", size: "12px" },
  "Project Type": { status: true, message: "", size: "12px" },
  "State": { status: true, message: "", size: "12px" },
  County: { status: true, message: "", size: "12px" },
  "Total Square Footage": { status: true, message: "", size: "12px" },
  "Number of Stories": { status: true, message: "", size: "12px" },
  "Zip Code": { status: true, message: "", size: "12px" },
  "Funded Date": { status: true, message: "", size: "12px" },
  "Maturity Date": { status: true, message: "", size: "12px" },
  "Expected Completion Date": { status: true, message: "", size: "12px" },
  "Lot Acquisition Date": { status: true, message: "", size: "12px" },
  "Completed Date": { status: true, message: "", size: "12px" },
  "Lot cost": { status: true, message: "", size: "12px" },
};

export const SET_BUDGET_ITEMS = "SET_BUDGET_ITEMS";
export const SET_DELETE_BUDGET_ITEM = "SET_DELETE_BUDGET_ITEM";
export const SET_TABLEAU_TOKEN = "SET_TABLEAU_TOKEN";
export const SET_UNITS = "SET_UNITS";

export const SET_INSPECTIONS = "SET_INSPECTIONS";

export const READ_ONLY_USER = ["accountexecutive"];

export const SET_ALL_COMPANIES = "SET_ALL_COMPANIES";

export const SET_BUDGET_CATEGORIES = "SET_BUDGET_CATEGORIES";
