import { gql } from "@apollo/client";

export const GET_UNITS = gql`
  query getProjUnits($pId: ID!, $cId: ID!) {
    GetProjUnits(pId: $pId, cId: $cId) {
      unitId
      unitName
      stories
      sqFt
      beds
      baths
      gar
      apprVal
      intVal
      mlsListingDt
      mlsPendingDt
      closingDt
      isPresold
    }
  }
`;
