import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import { setUserInfo, userInfoSelector } from "../../redux";
import {
  Conatiner,
  LogoBox,
  ActionButton,
  ButtonGroup,
  IconButton,
  DetailsContainer,
  DropdownText,
  StyledIconButton,
  SearchInput,
  SearchButton
} from "./Navbar.styles";
import LevelLogo from "../../assets/images/Common/Vertical_iq.png";
import Draft from "../../assets/images/Navbar/draft.tsx";
import HomeIcon from "../../assets/images/Navbar/homeIcon.tsx";
import DropdownIcon from "../../assets/images/Navbar/logout.png";
import DropdownIconRed from "../../assets/images/Navbar/dropdown-red.svg";
import { signOut } from "../../utils/cognito-services";
import { convertFirstLetterToUpperCase } from "../../utils/common/validation";
import Button from "../Button";
import { Color } from "../../styles/Colors.ts";

export const NavBar = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoSelector);
  const { signInUserSession } = userInfo || {};
  const { idToken } = signInUserSession || {};
  const { payload } = idToken || {};
  const customPayload = payload || {};
  const { name } = customPayload || {};

  const companyId = customPayload["custom:companyId"]
    ? customPayload["custom:companyId"].toLowerCase()
    : "";
  const navigate = useNavigate();
  const [showLogoutText, setShowLogoutText] = useState(false);
  const [activeButton, setActiveButton] = useState("Home");
  const { pathname } = useLocation();
  const logoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (pathname === "/") {
      setActiveButton("Home");
    } else if (pathname === "/contact-us") {
      setActiveButton("Contact Us");
    }
  }, [pathname]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setShowLogoutText(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [logoutRef]);

  const CustomIconButton = ({
    text,
    Icon,
    onClick,
  }: {
    text: string;
    Icon?: any;
    onClick: () => void;
  }) => {
    const isActive = activeButton === text;
    return (
      <StyledIconButton isActive={isActive} onClick={onClick}>
        <IconButton
          onClick={() => {
            setActiveButton(text);
          }}
        >
          <Icon color={isActive ? Color.secondary : ""} />
          <span>{text}</span>
        </IconButton>
      </StyledIconButton>
    );
  };

  const logout = (e) => {
    e.stopPropagation();
    signOut({ user: userInfo }, () => {
      dispatch(setUserInfo({}));
      goToWelcome();
    });
  };

  const goToContactUs = () => {
    navigate("/contact-us");
  };

  const goToWelcome = () => {
    navigate("/");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const goToProjects = () => {
    if (companyId) {
      navigate(`/home/${companyId}`);
    }
  };

  const userName = convertFirstLetterToUpperCase(name ?? "");

  const handleSearch = () => {
    navigate(`/home/${companyId}`, { state: { searchQuery } });
  };

  return (
    <Conatiner>
      <LogoBox>
        <img src={LevelLogo} alt="level" />
      </LogoBox>
      { companyId && 
        pathname !== "/contact-us" && 
        // pathname !== "/home/analytics" && 
        <div style={{ display: "flex", alignItems: "center" }}>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Filter by Project Name, ID or Address"
          />
           <SearchButton onClick={handleSearch}>
              Search
            </SearchButton>
          </div>
          
      }
      <ActionButton>
        <ButtonGroup>
          {!name && (
            <CustomIconButton
              text={"Home"}
              Icon={HomeIcon}
              onClick={goToWelcome}
            />
          )}
          {companyId && pathname === "/contact-us" && (
            <CustomIconButton
              text={"Dashboard"}
              Icon={HomeIcon}
              onClick={goToProjects}
            />
          )}
          <CustomIconButton
            text={"Contact Us"}
            Icon={Draft}
            onClick={goToContactUs}
          />
        </ButtonGroup>
        <DetailsContainer signInUserSession={signInUserSession}>
          {signInUserSession ? (
            <>
              <p>Hi {userName}</p>
              <div ref={logoutRef}>
                <button onClick={() => setShowLogoutText(!showLogoutText)}>
                  <img src={DropdownIcon} alt="dropdown_icon" />
                </button>

                {showLogoutText && (
                  <DropdownText onClick={(e) => logout(e)}>
                    <img src={DropdownIconRed} alt="dropdown_icon" />
                    Logout
                  </DropdownText>
                )}
              </div>
            </>
          ) : (
            <Button onClick={goToLogin}>Login</Button>
          )}
        </DetailsContainer>
      </ActionButton>
    </Conatiner>
  );
};

export default NavBar;
