import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Customized,
} from "recharts";
import { getInspections } from "../../redux";
import { formatInspection } from "../../utils";
import { useSelector } from 'react-redux';
import { projectByIdSelector } from '../../redux';
import styled from 'styled-components';

const ChartContainer = styled.div`
  .scatter-chart {
    svg {
      border: none !important;
      outline: none !important;
    }
  }
`;

interface Coordinate {
  x: number;
  y: number;
}

const formatDateString = (timestamp: number): string => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getFullYear()}`;
};

const generateMonthlyTicks = (domainDates: number[]): number[] => {
  if (domainDates.length < 2) {
    return [];
  }
  const start = new Date(domainDates[0]);
  const end = new Date(domainDates[1]);
  end.setMonth(end.getMonth() + 1); // Increment the end date by one month
  end.setDate(1);
  const ticks: number[] = [];

  const monthCount = (end.getFullYear() - start.getFullYear()) * 12 +
         (end.getMonth() - start.getMonth());
  let monthInterval = 1;
  if (monthCount > 70) {
    monthInterval = 3;
  } else if (monthCount > 35) {
    monthInterval = 2;
  }
  let currentDate = new Date(start);
  currentDate.setDate(1); // Ensure we start on the 1st of the month

  while (currentDate <= end) {
    ticks.push(currentDate.getTime());
    currentDate.setMonth(currentDate.getMonth() + monthInterval);
  }
  return ticks;
};

const CustomShape = (props) => {
  const { cx, cy, fill } = props;
  return (
    <g>
      <line x1={cx - 8} y1={cy - 8} x2={cx + 8} y2={cy + 8} stroke={fill} strokeWidth={3} />
      <line x1={cx - 8} y1={cy + 8} x2={cx + 8} y2={cy - 8} stroke={fill} strokeWidth={3} />
    </g>
  );
};

const MyPolygon = (props) => {
  if (props.points.length === 0) {
    return null;
  }
  const scaledPoints = props.points.split(' ')
    .map(point => {
      const [x, y] = point.split(',');
      const scaledX = props.xAxisMap[0].scale(Number(x));
      const scaledY = props.yAxisMap[0].scale(Number(y));
      return `${scaledX},${scaledY}`;
    })
    .join(' ');
  if (scaledPoints.length === 0) {
    return null;
  }
  return (
    <g>
      <polygon
        points={scaledPoints}
        fill={props.fill || "rgba(0,255,0,0.1)"}
        stroke={props.stroke || "green"}
      />
    </g>
  );
};
export const ProjectProgressChart: React.FC<{
  pId: string | undefined;
  cId: string | undefined;
  onReady?: () => void;
}> = ({ pId, cId, onReady }) => {

  const dispatch = useDispatch();
  const [inspections, setInspections] = useState<any[]>([]);
  const [points, setPoints] = useState<Coordinate[]>([])
  const { currentProject } = useSelector(projectByIdSelector);
  const { matDate, expCompdate, fundedDate, predDate } = currentProject || {};
  const inspectionsState = useSelector(state => {
    return state.inspections?.inspections;
  });
  const startDate = fundedDate ? new Date(new Date(fundedDate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  const maturityDate = matDate ? new Date(new Date(matDate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  const expectedCompletionDate = expCompdate ? new Date(new Date(expCompdate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  const predictedCompletionDate = predDate ? new Date(new Date(predDate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  const minPredictedCompletionDate = predictedCompletionDate ? new Date(new Date(predictedCompletionDate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  if (minPredictedCompletionDate)
    minPredictedCompletionDate.setDate(minPredictedCompletionDate.getDate() - 15);
  const maxPredictedCompletionDate = predictedCompletionDate ? new Date(new Date(predictedCompletionDate).toLocaleString('en-US', { timeZone: 'UTC' })) : null;
  if (maxPredictedCompletionDate)
    maxPredictedCompletionDate.setDate(maxPredictedCompletionDate.getDate() + 15);

  const getDomain = () => {
    if (!inspections || inspections.length < 2) {
      return []
    }
    let dates: number[] = [];
    if (startDate) dates.push(startDate.getTime());
    if (minPredictedCompletionDate) dates.push(minPredictedCompletionDate.getTime());
    if (inspections && inspections[0]) dates.push(inspections[0].time);
    let minDate = new Date(Math.min(...dates));
    minDate.setMonth(minDate.getMonth() - 1);
    dates = [];
    if (maturityDate) dates.push(maturityDate.getTime());
    if (expectedCompletionDate) dates.push(expectedCompletionDate.getTime());
    if (maxPredictedCompletionDate) dates.push(maxPredictedCompletionDate.getTime());
    if (inspections && inspections[inspections.length - 1]) dates.push(inspections[inspections.length - 1].time);
    let maxDate = new Date(Math.max(...dates));
    maxDate.setMonth(maxDate.getMonth() + 1);
    const domain = [
      minDate.getTime(),
      maxDate.getTime()
    ];
    return domain;
  };

  const domainMonths = getDomain();

  const formatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });

  useEffect(() => {
    const loadInspectionData = async () => {
      if (!pId || !cId) {
        console.log("INSPECTIONS(ProjectProgressChart) => Missing pId or cId");
        return;
      }

      const result = await new Promise((resolve) => {
        dispatch(getInspections({ pId, cId }, (items) => {
          resolve(items);
        }));
      });

      if (Array.isArray(result) && result.length > 0) {
        const modifiedProducts = formatInspection([...result]);

        const formattedData = modifiedProducts.map(item => ({
          time: item.inspDate.display ?
          new Date(new Date(item.inspDate.display).toLocaleString('en-US', { timeZone: 'UTC' })).getTime() : null,
          percent_complete: parseFloat(item.inspCompleted.replace('%', ''))
        })).sort((a, b) => a.time - b.time);

        setInspections(formattedData);

        if (formattedData.length > 0) {
          const latestProgress = formattedData[formattedData.length - 1];
          setPoints([
            { x: latestProgress.time, y: latestProgress.percent_complete },
            { x: minPredictedCompletionDate ? minPredictedCompletionDate.getTime() : null, y: 100 },
            { x: maxPredictedCompletionDate ? maxPredictedCompletionDate.getTime() : null, y: 100 },
          ]);
        }
        onReady?.();
      }
    };

    loadInspectionData();
  }, [pId, cId, onReady, inspectionsState, dispatch]);

  return (
    <ChartContainer>
      <ScatterChart
        width={1006}
        height={716}
        margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
        style={{
          border: "none !important",
          outline: "none !important",
          backgroundColor: "white"
        }}
        className="scatter-chart"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="time"
          type="number"
          domain={domainMonths}
          tickFormatter={formatDateString}
          tick={{
            angle: -45,
            textAnchor: "end",
            dy: 0,
            fontSize: "14px",
            fill: "black"
          }}
          ticks={generateMonthlyTicks(domainMonths)}
          interval={0}
          />
        <YAxis
          dataKey={"percent_complete"}
          type="number"
          label={{
            value: "Percent Complete",
            angle: -90,
            position: "insideLeft",
            style: { fontSize: "14px", fill: "black" },
          }}
          domain={[0, 100]}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tick={{ fontSize: "14px", fill: "black" }}
        />
        <Tooltip
          labelFormatter={(label) => new Date(label).toLocaleDateString()}
        />
        <Scatter
          name="Progress"
          data={inspections}
          fill="blue"
          shape={<CustomShape />}
        />
        {startDate && (
          <ReferenceLine
            x={startDate ? startDate.getTime() : 0}
            stroke="green"
            orientation={-90}
            label={{
              value: startDate ? `Start Date (${formatter.format(startDate)})` : "",
              offset: 20,
              angle: -90,
              position: "insideBottomLeft",
              style: { fontSize: "14px", fill: "black" },
            }}
          />
        )}
        {(maturityDate || expectedCompletionDate) && (
          <ReferenceLine
            x={maturityDate ? maturityDate.getTime() : expectedCompletionDate ? expectedCompletionDate.getTime() : 0}
            stroke="red"
            orientation={-90}
            label={{
              value: `${maturityDate ? "Maturity" : "Expected Completion"} Date (${maturityDate ? formatter.format(maturityDate!!) : formatter.format(expectedCompletionDate!!)})`,
              offset: 20,
              angle: -90,
              position: "insideBottomLeft",
              style: { fontSize: "14px", fill: "black" },
            }}
          />
        )}
        {points.length > 2 && (
          <Customized
            name="ConePoly"
            points={points ? points.map((point) => `${point.x},${point.y}`).join(" ") : []}
            stroke="rgba(0,255,0,0.75)"
            fill="rgba(0,255,0,0.25)"
            component={MyPolygon}
          />
        )}
      </ScatterChart>
    </ChartContainer>
  );
};

export default ProjectProgressChart;
