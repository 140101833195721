import React, { useState, useRef, useEffect } from "react";
import {
  CustomDialogFooter,
  FormGrid,
  Header,
  Heading,
  SubHeading,
  Conatiner,
  CloseButton,
} from "./InspectionModal.styles";

import InputBox from "../../InputField/InputField";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import { validateDate } from "../../../utils";
import Calendar from "../../DatePicker/DatePicker";
import { InspectionModalProps } from "../../../types";

const defaultError = {
  inspectionDate: { status: true, message: "" },
  complete: { status: true, message: "" },
};

export const InspectionModal: React.FC<InspectionModalProps> = ({
  onClose,
  saveProduct,
  isEdit,
  isOpen,
  inputValues,
  setInputValues,
  pName,
  btnLoading,
}) => {
  const [errorFields, setErrorFields] = useState({ ...defaultError });

  // Ref to focus on Inspection Date
  const inspectionDateRef = useRef(null);

  useEffect(() => {
    if (isOpen && inspectionDateRef.current) {
      inspectionDateRef.current.focus();
    }
  }, [isOpen]);

  const checkValidation = () => {
    let status = true;
    const error = { ...errorFields };
    for (let name in errorFields) {
      if (inputValues[name].value === "") {
        error[name] = {
          status: inputValues[name].value !== "",
          message: inputValues[name].value === "" ? "*Required field" : "",
        };
        status = status && inputValues[name].value !== "";
      }
      if (inputValues[name].type === "date") {
        const { status: isCorrectDate } = validateDate(inputValues[name].value);
        error[name] = {
          status: isCorrectDate,
          message: isCorrectDate ? "" : "Enter date in MM/DD/YYYY format.",
        };
        status = status && isCorrectDate;
      }
    }
    setErrorFields(error);
    return status;
  };

  const saveProductHandler = () => {
    if (checkValidation()) {
      saveProduct();
    }
  };

  const ModalFooter = (
    <CustomDialogFooter>
      <Button type="button" className="btn-default" onClick={onClose}>
        Cancel
      </Button>
      <Button
        type="button"
        className="btn-primary"
        onClick={saveProductHandler}
        isLoading={btnLoading}
      >
        Save
      </Button>
    </CustomDialogFooter>
  );

  const resetError = () => {
    setErrorFields({ ...defaultError });
  };

  const onChangeHandler = (event, value, name) => {
    setInputValues({
      ...inputValues,
      [name]: { ...inputValues[name], value: value },
    });
    resetError();
  };
  const onBlurhandler = (value, key) => {
    const { status } = validateDate(value);
    if (value === "" || value === "Invalid" || !status) {
      setErrorFields({
        ...errorFields,
        [key]: {
          status: false,
          message: "Enter date in MM/DD/YYYY format.",
        },
      });
    }
  };
  const ModalHeader = (
    <Header>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <div>
        <Heading>{!isEdit ? "Create" : "Edit"} Inspection</Heading>
        <SubHeading>{pName}</SubHeading>
      </div>
    </Header>
  );

  return (
    <Conatiner>
      <Modal
        open={isOpen}
        header={ModalHeader}
        hideCloseIcon
        footer={ModalFooter}
        onClose={onClose}
      >
        <FormGrid>
          {Object.entries(inputValues).map(([key, item], index) => {
            const { maxLength, required, type, value, name } = item || {};
            if (name === "Inspection Id") return "";

            if (name === "Inspection Date") {
              return (
                <Calendar
                  name={name}
                  value={value}
                  onChange={(value) => {
                    onChangeHandler({}, value, key);
                  }}
                  error={errorFields[key]}
                  onBlur={() => {
                    onBlurhandler(value, key);
                  }}
                  key={index}
                  required={required}
                  autoFocus={true} // Use the autoFocus prop to focus this Calendar
                  inputRef={inspectionDateRef} // Pass ref to Calendar for focus
                />
              );
            }

            return (
              <div key={index}>
                <InputBox
                  value={value}
                  name={name}
                  type={type}
                  showLabel
                  required={required}
                  onChange={(event, value) =>
                    onChangeHandler(event, value, key)
                  }
                  maxLength={maxLength}
                  errorMessage={errorFields[key]}
                  isDollorIcon={name === "Draw Amount"}
                />
              </div>
            );
          })}
        </FormGrid>
      </Modal>
    </Conatiner>
  );
};

export default InspectionModal;