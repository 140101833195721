// @ts-nocheck
import React, { useState } from "react";
import Tooltip from "../Tooltip/Tooltip";

import {
  InputFieldContainer,
  InputBox,
  CustomLabel,
  ErrorMessage,
  InputEndIcon,
  InputBoxContainer,
} from "./InputField.styles";
import { checkNumberValidation } from "../../utils";
import EyeIcon from "../../assets/images/Common/EyeIcon.png";
import { InputFieldProps } from "../../types";

const InputField: React.FC<InputFieldProps> = ({
  labelIcon,
  InputIcon,
  name,
  type = "text",
  onChange = () => {},
  showPlaceHolder,
  showLabel,
  value,
  placeholder,
  isInputArea = false,
  errorMessage = { status: true, message: "", size: "14px" },
  onBlur,
  showTooltip,
  tooltipContent,
  disabled,
  maxLength,
  required,
  autoComplete,
  showPasswodIcon,
  isDollorIcon,
  step,
  max,
  dataType,
  regexPattern,
  autoFocus
}) => {
  const [inputData, setInputData] = useState(value);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });

  const getInputType = () => {
    if (type === "password") {
      if (isShowPassword) {
        return "text";
      }
    }
    return type;
  };
  const onChangeHandler = (event) => {
    const inputValue = event.target.value;
    if (type === "text" && /~/g.test(inputValue)) {
      return;
    }
    if (type === "float" && name !== "Draw Amount") {
      const numericValue = parseFloat(inputValue);
      if (numericValue > 100) {
        setError({
          status: false,
          message: "Value should not be greater than 100",
        });
        return;
      }
      if (numericValue < 1) {
        const decimalPart = inputValue.split(".")[1];
        if (decimalPart && decimalPart.length > 1) {
          setError({
            status: false,
            message: " ",
          });
          return;
        }
      }
    
      setError({
        status: true,
        message: "",
      });
    }
    if (type === "number") {
      if (isDollorIcon) {
        const [integer, decimal = ""] = inputValue.split(".");
        if (
          ((integer && integer.length > 9) ||
            (decimal && decimal.length > 2)) &&
          parseFloat(inputValue) > 999999999.99
        ) {
          setError({
            status: false,
            message:
              "Maximum amount should not be greater than $999,999,999.99",
          });
          return;
        } else {
          setError({
            status: true,
            message: "",
          });
        }
      }
      if (
        (/^\d*\.?\d{0,2}$/.test(inputValue) || inputValue === "") &&
        checkNumberValidation(name, maxLength, inputValue)
      ) {
        if (name === "% Complete" && parseFloat(inputValue) > 100) return;
        const modifiedValue = Number(inputValue);
        setInputData(modifiedValue);
        onChange(event, modifiedValue);
      } else {
        return;
      }
    } else if (regexPattern) {
      if (regexPattern.test(inputValue) || inputValue === "") {
        setInputData(inputValue);
        onChange(event, inputValue);
      } else {
        return;
      }
    } else if (dataType === "alphanumeric") {
      if (/^[a-zA-Z0-9\s]+$/.test(inputValue) || inputValue === "") {
        setInputData(inputValue);
        onChange(event, inputValue);
      } else {
        return;
      }
    } else if (dataType === "alphanumericWithSpecialChars") {
      if (/^[a-zA-Z0-9-_/\s]+$/g.test(inputValue) || inputValue === "") {
        setInputData(inputValue);
        onChange(event, inputValue);
      } else {
        return;
      }
    } else if (dataType === "text") {
      if (/^[a-zA-Z\s]+$/.test(inputValue) || inputValue === "") {
        setInputData(inputValue);
        onChange(event, inputValue);
      } else {
        return;
      }
    } else {
      setInputData(inputValue);
      onChange(event, inputValue);
    }
  };

  React.useEffect(() => {
    setInputData(value);
  }, [value]);

  return (
    <InputFieldContainer>
      {showLabel && (
        <CustomLabel className={required && "required"}>
          {name} {labelIcon && <img src={labelIcon} alt={name} />}
          {showTooltip && (
            <Tooltip id="passwordTooltip" content={tooltipContent} />
          )}
        </CustomLabel>
      )}
      <InputBoxContainer>
        <InputBox
          errorMessage={errorMessage?.status || error.status}
          data-testid={`input-${name}`}
          isInputArea={isInputArea}
        >
          {InputIcon && <img src={InputIcon} alt={name} />}
          {isDollorIcon && value && <span>$</span>}
          <input
            type={getInputType()}
            name={name}
            {...(showPlaceHolder && { placeholder: placeholder || name })}
            value={inputData ?? ""}
            onChange={onChangeHandler}
            onBlur={onBlur}
            maxLength={maxLength}
            disabled={disabled}
            autoComplete={autoComplete}
            aria-autocomplete="none"
            min="0"
            step={step}
            max={max}
            onWheel={(e) => {
              e.target.blur();
            }}
            onKeyDown={(event) => {
              if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
              }
            }}
            autoFocus={autoFocus}
          />
          {type === "password" &&
            inputData &&
            inputData.length > 0 &&
            showPasswodIcon && (
              <InputEndIcon
                src={EyeIcon}
                alt="eye_icon"
                onClick={() => {
                  setIsShowPassword((prevState) => !prevState);
                }}
              />
            )}
        </InputBox>
        {(errorMessage?.message || error?.message) && (
          <ErrorMessage errorMessageSize={errorMessage?.size}>
            {errorMessage.message || error?.message}
          </ErrorMessage>
        )}
      </InputBoxContainer>
    </InputFieldContainer>
  );
};

export default InputField;
