import { gql } from "@apollo/client";

export const GET_PROJECT_BY_ID = gql`
  query GetProjectByProjectId($cId: ID!, $pId: ID!) {
    GetProjectByProjectId(cId: $cId, pId: $pId) {
      actionPlan
      city
      pId
      pName
      comments
      county
      matDate
      expCompdate
      fundedDate
      lotAcqDate
      compDate
      lotCost
      pm
      pType
      ssi
      st
      pLoanId
      addr
      tlSqFoot
      zip
      maxStories
      predDate
    }
  }
`;
