import { jwtDecode } from "jwt-decode";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import { signOut } from "../cognito-services";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};

const attributes = (key, value) => {
  return {
    Name: key,
    Value: value,
  };
};

export const s3Config = () => {
  return {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
  bucket: process.env.REACT_APP_BUCKET,
  }
}

export const getCognitoAttributeList = ({
  email,
  agent,
  username,
  companyId,
  userType,
  companyType,
  companyDesc,
  companyName,
  companyTypeId,
}) => {
  let attributeList = [];
  let cognitoAttributeList = [];
  attributeList.push(attributes("email", email));
  attributeList.push(attributes("name", username));
  attributeList.push(attributes("custom:companyId", companyId));
  attributeList.push(attributes("custom:userType", userType));
  attributeList.push(attributes("custom:companyType", companyType));
  attributeList.push(attributes("custom:companyDesc", companyDesc));
  attributeList.push(attributes("custom:companyName", companyName));
  attributeList.push(attributes("custom:company-Type-Id", `${companyTypeId}`));
  attributeList.forEach((element) => {
    cognitoAttributeList.push(new CognitoUserAttribute(element));
  });

  return cognitoAttributeList;
};

export const getCognitoUser = (email) => {
  const userData = {
    Username: email,
    Pool: getUserPool(),
  };

  return new CognitoUser(userData);
};

export const getUserPool = () => {
  return new CognitoUserPool(poolData);
};

export const getAuthDetails = (email, password) => {
  var authenticationData = {
    Username: email,
    Password: password,
  };

  return new AuthenticationDetails(authenticationData);
};

export const decodeJWTToken = (userData) => {
  const { accessToken, idToken, refreshToken } = userData || {};
  const { jwtToken: userAccessToken } = accessToken || {};
  const { jwtToken: userIdToken } = idToken || {};
  const { token: userRefreshToken } = refreshToken || {};
  const { email, name, "custom:BuilderId": builderId } = jwtDecode(userIdToken);
  return {
    email,
    name,
    builderId,
    userAccessToken,
    userIdToken,
    userRefreshToken,
  };
};

export async function authUser() {
  const currentUser = getCurrentUser();

  if (currentUser === null) {
    return false;
  }

  const data = await getUserToken(currentUser);

  return data;
}

function getUserToken(currentUser) {
  return new Promise((resolve, reject) => {
    currentUser.getSession(function (err, session) {
      if (err) {
        reject(err);
        signOut({ user: currentUser }, () => {
          window.location.href = "/";
        });
        return;
      }
      resolve(currentUser);
    });
  });
}

function getCurrentUser() {
  const userPool = new CognitoUserPool(poolData);
  return userPool.getCurrentUser();
}
