import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query GetProjectsByCompanyId($cId: ID!) {
    GetProjectsByCompanyId(cId: $cId) {
      actionPlan
      city
      pId
      pName
      comments
      county
      matDate
      expCompdate
      fundedDate
      lotAcqDate
      compDate
      lotCost
      pm
      pType
      ssi
      st
      pLoanId
      addr
      tlSqFoot
      zip
      maxStories
      predDate
    }
  }
`;
