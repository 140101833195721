import { parse } from "date-fns";
import { projectFields } from "./constants";
import { PROJECT_TYPE_OPTIONS } from "../../constants";

export const trimDescFromType = (value) => {
  const [companyType, companyDesc] = value?.split("#") || [];
  return { companyType, companyDesc };
};

export const convertFirstLetterToUpperCase = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isValidEmail = (email: string) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(email)) {
    return { status: false, message: "Please enter a valid email address." };
  }

  return { status: true, message: "Email is valid." };
};

export const isValidPassword = (password: string) => {
  if (password.length < 7 || password.length > 16) {
    return {
      status: false,
      message: "Password length must be between 7 and 16 characters.",
    };
  }
  if (!/\d/.test(password)) {
    return {
      status: false,
      message: "Password must contain at least one number.",
    };
  }
  if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
    return {
      status: false,
      message: "Password must contain at least one special character.",
    };
  }
  if (!/[A-Z]/.test(password)) {
    return {
      status: false,
      message: "Password must contain at least one uppercase letter.",
    };
  }
  if (!/[a-z]/.test(password)) {
    return {
      status: false,
      message: "Password must contain at least one lowercase letter.",
    };
  }

  // If all criteria pass, return true
  return { status: true, message: "Password meets the requirements." };
};

export const validateDate = (date: string = "") => {
  if (!date || !date.length)
    return { status: false, message: "Please enter a date" };
  let parsed: Date | null = null;
  const [mm, dd, yyyy] = date.split("/") || [];

  if (date.match(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/) && yyyy > 1900) {
    parsed = parse(date, "MM/dd/yyyy", new Date());
  }

  if (!parsed || parsed?.toString() === "Invalid Date")
    return {
      status: false,
      message: "Invalid date format,please Enter Date in MM/DD/YYYY format.",
    };

  return {
    status: true,
    message: "",
  };
};

export const formatObjToKeyValuePair = (inputValues) => {
  const obj = {};
  for (const [key, value] of Object.entries(inputValues)) {
    const attribute = value.mappedTo;
    if (value.type === "dropdown") {
      const { value: inputValue } = value || {};
      const {
        value: selectedValueFromDropdown,
        label: selectedLabelFromDropdown,
      } = inputValue || {};
      if (value.mappedTo === "pType") {
        obj[attribute] = selectedValueFromDropdown
          ? selectedValueFromDropdown
          : "";
      } else {
        obj[attribute] = selectedLabelFromDropdown
          ? selectedLabelFromDropdown
          : "";
      }
    } else if (value.type === "date" && value["value"]) {
      const { value: inputValue } = value || {};
      obj[attribute] = convertDatetoAWSFormat(inputValue);
    } else {
      const { value: inputValue } = value || {};

      if (inputValue) {
        obj[attribute] = inputValue;
      }
    }
    console.log("obj for attribute: ", obj[attribute]);
  }

  return obj;
};

export const formatAddress = (
  {
    stAddress = "",
    city = "",
    stateCode = "",
  }: {
    stAddress?: string | null;
    city?: string | null;
    stateCode?: string | null;
  },
  useCommas: boolean = false
): string => {
  const connector = useCommas ? ", " : " ";

  return `${stAddress}${stAddress && connector}${city}${
    city && connector
  }${stateCode}${stateCode && " "}`;
};

export const mapCurrentProjectFields = (currentProject) => {
  const obj = {};
  for (const [key, value] of Object.entries(projectFields)) {
    const { mappedTo } = value || {};
    const dbValue = currentProject[mappedTo];
    if (value.type === "dropdown") {
      obj[key] = {
        ...value,
        value: {
          value: PROJECT_TYPE_OPTIONS.find((item) => item.label === dbValue)
            ?.value,
          label: dbValue,
        },
      };
    } else {
      obj[key] = {
        ...value,
        value: value.type === "date" ? convertDateToMMDDYYYY(dbValue) : dbValue,
      };
    }
  }
  return obj;
};

export const checkNumberValidation = (
  name: string,
  maxLength?: number,
  value?: string
) => {
  switch (name) {
    case "Zip Code":
    case "Number of stories":
    case "Square Feet":
    case "Number of Bedrooms":
    case "Garage Stalls":
    case "Total Square Footage":
    case "Number of Stories":
    case "% Complete":
      return (
        (maxLength && 10 ** maxLength - 1 >= parseInt(value)) || value === ""
      );
    case "Lot cost":
    case "Draw Amount":
    case "Amount":
    case "Appraised Value":
    case "Internal Value":
    case "Number of Bathrooms":
      if (
        (maxLength && 10 ** maxLength - 1 >= parseInt(value)) ||
        value === ""
      ) {
        if (
          (value?.startsWith("0") && name !== "Number of Bathrooms") ||
          value?.startsWith(".")
        )
          return false;
        const [integerValue, decimalValue] = value?.split(".") || "";
        if (value?.length > (decimalValue ? 12 : 11)) return false;
        if (decimalValue && decimalValue.length > 2) return false;
      } else {
        return false;
      }

      return true;
    default:
      return true;
  }
};

export const normalizeCurrencyString = (
  currencyString: string = ""
): string | number => {
  if (currencyString && typeof currencyString === "string") {
    return +currencyString.replace(/[$,]/g, "");
  }
  return currencyString;
};

export const formatCurrency = (
  value: string | number = 0,
  fallback: string = ""
): string => {
  const amount: any =
    typeof value === "string" ? normalizeCurrencyString(value) : value;

  if (!amount || Number.isNaN(Number(amount))) return fallback;

  try {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, // Ensure two decimal places
    });

    return formatter.format(amount); // Removed .replace(/\.00/, "") to retain decimals
  } catch (e) {
    console.error("[formatCurrency] Error formatting value: ", e);
    return fallback;
  }
};

export const convertDatetoAWSFormat = (date) => {
  const [mm, dd, yyyy] = date.split("/") || [];
  return date ? `${yyyy}-${mm}-${dd}` : "";
};

export const convertDateToMMDDYYYY = (date) => {
  if (!date || isNaN(new Date(date).getTime())) return "";
  const d = new Date(date);
  return `${String(d.getMonth() + 1).padStart(2, "0")}/${String(d.getDate()).padStart(2, "0")}/${d.getFullYear()}`;
};

export const formatUnits = (data) => {
  const newItems: any = [];
  data.forEach((item) => {
    newItems.push({
      ...item,
      apprVal: formatCurrency(item.apprVal),
      intVal: formatCurrency(item.intVal),
      mlsListingDt: convertDateToMMDDYYYY(item.mlsListingDt),
      mlsPendingDt: convertDateToMMDDYYYY(item.mlsPendingDt),
      closingDt: convertDateToMMDDYYYY(item.closingDt),
      isPresold: item.isPresold === 1 ? "Yes" : item.isPresold === 0 ? "No" : item.isPresold,
    });
  });
  return newItems;
};

export const convertCurrencyToNumber = (currency) => {
  if (!currency) return;
  return currency.replace(/[^0-9.-]+/g, "");
};

export const formatInspection = (data) => {
  const newItems: any = [];
  data.forEach((item) => {
    newItems.push({
      inspDrawAmt: item.inspDrawAmt === null || item.inspDrawAmt === undefined
                ? null 
                : formatCurrency(item.inspDrawAmt),
      inspCompleted: item.inspCompleted > 0 
                ? `${parseFloat(item.inspCompleted).toFixed(1)}%` 
                : '0.0%',
      inspDate: {
        display: convertDateToMMDDYYYY(item.inspDate),
        sortValue: new Date(item.inspDate),
      },
      inspId: item.inspId,
    });
  });

  return newItems;
};

export const convertPercentageToInt = (value) => {
  return value.replace(/%/g, "");
};

export const formatBudgetItems = (items, pId, cId) => {
  const newData = [];
  items.forEach((item) => {
    const modifiedItem = {};
    modifiedItem.bdgtCategory = item?.Category?.label;
    modifiedItem.bdgtDesc = item["Budget Item Description"];
    modifiedItem.amt = parseFloat(item.Amount);
    modifiedItem.pId = pId;
    modifiedItem.cId = cId;

    newData.push(modifiedItem);
  });

  return newData;
};

export const validateSpecialChars = (value) => {
  if (!/^[a-z\d\-_\s]+$/i.test(value)) {
    return {
      status: false,
    };
  }
  return {
    status: true,
  };
};

export const extractDate = (dateString: string) => {
  const [month, dd, yyyy] = dateString.slice(4, 15).split(" ");
  const monthToNum = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  if (dateString) {
    const mm = monthToNum[month] || "Unknown";
    return `${mm}/${dd}/${yyyy}`;
  } else {
    return "";
  }
};
