import React from "react";
import NavBar from "../Navbar/Navbar";
import InputField from "../InputField/InputField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledToast } from "../CreateNewUser/CreateNewUser.styles";
import BackgroundImage from "../../assets/images/Welcome/contactus.svg";

import {
  Heading,
  ContactContainer,
  FormContent,
  ParentContainer,
  ConnectContent,
  HourDisplay,
  ContactSection,
  RootContainer,
  ContactImageContainer,
  ErrorMessage,
  ErrorBox,
} from "./ContactUs.style";
import Button from "../Button";
import Footer from "../Footer/Footer";
import {
  mailLogo,
  phoneLogo,
  locationLogo,
} from "../../assets/images/Welcome/index";
import {
  isValidEmail,
  hasAnyErrorStatusFalse,
  hasAnyEmptyFiled,
} from "../../utils";
import { sendEmail } from "../../redux";
import { useDispatch } from "react-redux";

const input = {
  "Company Name": "",
  "User Email": "",
  Name: "",
  State: "",
  Phone: "",
};

const defaultInputError = {
  "Company Name": { status: true, message: "", size: "12px" },
  "User Email": { status: true, message: "", size: "12px" },
  Name: { status: true, message: "", size: "12px" },
  State: { status: true, message: "", size: "12px" },
};

export const ContactUs = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState(defaultInputError);
  const [inputValues, setInputValues] = React.useState({ ...input });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    // if (name === "Phone") {
    //   const regex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}(?:\s*(?:x|ext\.?|#)\s*\d{1,5})?$/i;
    //   if (value !== "" && !regex.test(value)) {
    //     setIsError(true);
    //   } else {
    //     setIsError(false);
    //   }
    // }

    setInputValues({
      ...inputValues,
      [name]: value,
    });
    setIsError(false);
  };

  const notifyError = (message) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = () => {
    const items = {
      name: inputValues["Name"],
      company: inputValues["Company Name"],
      state: inputValues["State"],
      email: inputValues["User Email"],
      phoneno: inputValues["Phone"],
    };
    if (
      !hasAnyErrorStatusFalse(errorMessage) &&
      !hasAnyEmptyFiled(inputValues)
    ) {
      setIsLoading(true);
      setIsError(false);
      dispatch(
        sendEmail({ items }, (data) => {
          const { statusCode } = data || {};
          setIsLoading(false);
          if (statusCode === 200) {
            notifySuccess(
              "Thank you for reaching out! We appreciate your interest. Our team will get back to you shortly regarding your inquiry."
            );
            setInputValues({ ...input });
            setErrorMessage(defaultInputError);
          } else {
            notifyError("Not able to send mail");
          }
        })
      );
    } else {
      setIsError(true);
    }
  };

  const onBlurHandler = (event) => {
    const name = event.target.name;
    const value = inputValues[name];
    if (name === "User Email") {
      const { status } = isValidEmail(value);
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status,
          message: status ? "" : "Please enter a valid email address",
        },
      });
    } else if (value === "") {
      setErrorMessage({
        ...errorMessage,
        [name]: {
          ...errorMessage[name],
          status: false,
          message: "Required field",
        },
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [name]: { ...errorMessage[name], status: true, message: "" },
      });
    }
  };

  return (
    <RootContainer>
      <div>
        <StyledToast />
        <NavBar />
        <Heading>
          <h1>Contact Us</h1>
        </Heading>
        <ContactSection>
          <ParentContainer>
            <ContactContainer>
              <ContactImageContainer>
                <img src={BackgroundImage} alt="img" />
              </ContactImageContainer>
              <FormContent>
                <h3>Interested in a quick demo or more information?</h3>
                <InputField
                  value={inputValues["Name"]}
                  name={"Name"}
                  type="text"
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  errorMessage={errorMessage["Name"]}
                  showLabel
                  maxLength={200}
                  autoComplete="off"
                  required
                />
                <InputField
                  value={inputValues["Company Name"]}
                  name={"Company Name"}
                  type="text"
                  showLabel
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  errorMessage={errorMessage["Company Name"]}
                  maxLength={200}
                  required
                />
                <InputField
                  value={inputValues["State"]}
                  name={"State"}
                  type="text"
                  showLabel
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  errorMessage={errorMessage["State"]}
                  maxLength={20}
                  required
                />
                <InputField
                  value={inputValues["User Email"]}
                  name={"User Email"}
                  type="text"
                  onChange={onChangeHandler}
                  onBlur={onBlurHandler}
                  errorMessage={errorMessage["User Email"]}
                  showLabel
                  maxLength={200}
                  autoComplete="off"
                  required
                />
                <InputField
                  value={inputValues["Phone"]}
                  showLabel
                  name={"Phone"}
                  type="text"
                  maxLength={20}
                  onChange={onChangeHandler}
                />
                {isError && (
                  <ErrorBox>
                    <ErrorMessage>Please fill Required* fields </ErrorMessage>
                  </ErrorBox>
                )}
                <Button
                  type="button"
                  className="btn-primary"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
              </FormContent>
              <ConnectContent>
                <h3>Connect with Us</h3>
                <div>
                  <img src={phoneLogo} alt="Phone" />
                  <p>425-406-2277</p>
                  <br></br>
                  <img src={mailLogo} alt="Mail" />
                  <p>info@vertical-iq.com</p>
                  <br></br>
                  <img src={locationLogo} alt="Location" />
                  <p>11250 Kirkland Way, Suite 100, Kirkland, WA 98033</p>
                  <HourDisplay>
                    <h6>Working Hours</h6>
                    <p>8 AM to 5PM PST (Monday to Friday)</p>
                  </HourDisplay>
                </div>
              </ConnectContent>
            </ContactContainer>
          </ParentContainer>
        </ContactSection>

        <Footer />
      </div>
    </RootContainer>
  );
};

export default ContactUs;
