import { convertDatetoAWSFormat, convertCurrencyToNumber } from "../../utils";

export const formatUnitData = (items) => {
  const obj = {};
  for (const [key, value] of Object.entries(items)) {
    const attribute = value.mappedTo;
    if (value.type === "date" && value["value"]) {
      const { value: inputValue } = value || {};
      obj[attribute] = convertDatetoAWSFormat(inputValue);
    } else if (value.type === "number") {
      const { value: inputValue } = value || {};

      obj[attribute] = Number(inputValue) || 0;
    } 
    else if (value.type === "dropdown") {
      let { value: inputValue } = value || {};
      inputValue = inputValue === "Yes" ? 1 : inputValue === "No" ? 0 : null;
      obj[attribute] = inputValue;
    } else {
      const { value: inputValue } = value || {};

      obj[attribute] = inputValue || null;
    }
  }

  return obj;
};

const getFieldValues = (dbValue, field) => {
  if (field.type === "number" && isNaN(dbValue)) {
    return convertCurrencyToNumber(dbValue);
  } else {
    return dbValue;
  }
};

export const mapCurrentUnitFields = (inputValues, product) => {
  const obj = {};
  const newInputValues = { ...inputValues };

  for (const [key, value] of Object.entries(newInputValues)) {
    const { mappedTo } = value || {};
    const dbValue = product[mappedTo];

    obj[key] = {
      ...value,
      value: getFieldValues(dbValue, value),
    };
  }
  return obj;
};
