import React from "react";

import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { thunk } from "redux-thunk";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import { useStore } from "../src/redux";
import { reducers } from "./redux/reducers";
import CreateNewUser from "./components/CreateNewUser/CreateNewUser";
import Welcome from "./components/Welcome/Welcome";
import ContactUs from "./components/ContactUs/ContactUs";

const App = () => {
  const reduxStore = createStore(reducers, {}, applyMiddleware(thunk));

  const store = useStore(reduxStore);

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route path="/home/*" element={<Home />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
