import React, { useState } from 'react';
import { ProjectProgressChart } from '../ProjectProgressChart/ProjectProgressChart';
import { useSelector } from 'react-redux';
import { projectByIdSelector } from '../../redux';
import { ReportContainer, CoverPage, ChartPage } from './EOCReport.styles';
import VerticalIqLogo from "../../assets/images/Common/Vertical_iq_logo.png" 
interface EOCReportProps {
  pId: string | undefined;
  cId: string | undefined;
  onChartReady?: () => void;
}

const EOCReport: React.FC<EOCReportProps> = ({ pId, cId, onChartReady }) => {
  const [isChartReady, setIsChartReady] = useState(false);
  const { currentProject } = useSelector(projectByIdSelector);
  const { pLoanId, pName, addr, city, st, zip, pType, tlSqFoot } = currentProject || {};
  const today = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <ReportContainer custom-id="report-container">
      {/* Cover Page */}
      <CoverPage custom-id="page">
        <div
          className="cover-header"
          style={{
            width: "100%",
            flex: "0 0 20%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img
            src={VerticalIqLogo}
            alt="Vertical IQ"
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
          <p>
            generated on:<br></br>
            {today}
          </p>
        </div>
        <div
          className="cover-content"
          style={{
            width: "100%",
            flex: "0 0 80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <h1>End of Construction Prediction for:</h1>
          <h2>{pLoanId ? pLoanId + " = " : ""}{pName}</h2>
          <h2>{addr}, {city}, {st} {zip}</h2>
          <h2>{pType} {tlSqFoot} SqFt</h2>
        </div>
      </CoverPage>

      {/* Progress Chart Page */}
      <ChartPage custom-id="page">
        <div
          style={{
            width: "100%",
            flex: "0 0 20%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <img
            src={VerticalIqLogo}
            alt="Vertical IQ"
            style={{ 
                height: "50px",
                width: "auto"
            }}
            onError={(e) => {
              e.currentTarget.style.display = 'none';
            }}
          />
          <h2 style={{
            flex: 1,
            textAlign: "center",
            margin: "0 20px",
          }}>
            Predicted End of Construction for:<br></br>{pLoanId ? pLoanId + " = " : ""}{pName}
          </h2>
          <p>
            generated on:<br></br>
            {today}
          </p>
        </div>
        <ProjectProgressChart
          pId={pId}
          cId={cId}
          onReady={onChartReady}
        />
      </ChartPage>
    </ReportContainer>
  );
};

export default EOCReport;
